<template>
  <div class="tc light-bg">
    <div style="font-weight: 700; font-size: 3rem; margin-top: 50px">404</div>
    <h2 class="error-message">页面未找到！</h2>
    <div class="mv-20">
      <van-search
        readonly
        placeholder="请输入搜索关键词"
        @click.native="$router.push({ name: 'search' })"
      />
    </div>
    <div class="mv-20 p-20">
      <van-button
        block
        round
        type="primary"
        @click="$router.push({ name: 'index' })"
        >返回首页</van-button
      >
    </div>
  </div>
</template>

<script>
import { Cell, Search, CellGroup } from "vant";
import Vue from "vue";
Vue.use(Cell).use(Search).use(CellGroup);

export default {};
</script>

<style></style>
